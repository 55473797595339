<template>
  <div id="app">
    <div id="map-container">
      <!-- Map Element -->
      <div id="map" ref="mapElement"></div>

      <!-- Center Map Button -->
      <el-button class="center-map-button" @click="centerMapOnIsrael">
        <el-icon>
          <Compass />
        </el-icon>
      </el-button>

      <!-- Time Slider Toggle Button -->
      <el-button
        ref="timeToggle"
        class="time-slider-toggle-button"
        type="primary"
        @click="toggleTimeSliderDrawer"
      >
        <el-icon><Clock /></el-icon>
      </el-button>

      <!-- Settings Button -->
      <el-button
        ref="settingsButton"
        class="settings-button"
        type="primary"
        @click="toggleSettings"
      >
        <el-icon><Setting /></el-icon>
      </el-button>

      <!-- Real-Time Toggle Button -->
      <el-button
        ref="timeToggleButton"
        class="real-time-button"
        :type="realTimeStream ? 'success' : 'danger'"
        @click="toggleRealTime"
        :loading="loading"
        :disabled="antiSpam"
        :class="{ 'animate-blink': realTimeStream }"
      >
        <span
          class="status-circle"
          :class="{ online: realTimeStream, offline: !realTimeStream }"
        ></span>
        {{ realTimeStream ? 'Real-Time' : 'Disconnected' }}
      </el-button>


      <!-- Floating Feed Button with Notification Badge -->
      <el-badge :value="newEventCount > 0 ? newEventCount : null" class="item">
        <el-button
          ref="newsButton"
          class="news-button"
          :icon="Tickets"
          type="primary"
          :style="newsButtonStyle"
          @click="toggleFeed"
        ></el-button>
      </el-badge>

      <!-- Traffic Meter -->
      <div class="traffic-meter">
        <div class="statistic-footer">
          <div class="footer-item">
            <el-tooltip content="Traffic Percentage" placement="left">
              <div
                class="traffic-percentage"
                :class="trafficColorClass"
                @click="calculateTraffic"
              >
                <el-icon class="traffic-icon">
                  <ArrowUp v-if="trafficPercentage > 0" />
                  <ArrowDown v-else />
                </el-icon>
                {{ Math.abs(trafficPercentage) }}%
              </div>
            </el-tooltip>
          </div>
          <div class="footer-item">
            <el-tooltip content="Most Active Location" placement="left">
              <div class="most-active-location" @click="centerOnMostActive">
                {{ mostActiveLocation || 'No Active Location' }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>

      <!-- BAZ Logo -->
      <div id="logo" @click="toggleAbout">BAZ</div>
    </div>
  <!-- Donation Button -->
      <el-button
        type="primary"
        class="donation-button"
        @click="openDonationLink"
      >
        BuyMeaCoffee
      </el-button>

    <!-- Legend -->
    <div id="legend" ref="legend">
      <div class="legend-item">
        <div class="legend-color" style="background-color: rgb(0, 0, 255);"></div>
        <span>Oldest</span>
      </div>
      <div class="legend-item">
        <div class="legend-color" style="background-color: rgb(255, 0, 0);"></div>
        <span>Newest</span>
      </div>
      <div class="legend-item">
        <div
          class="legend-color heat-dot"
          style="background-color: rgba(0, 50, 255, 1.5);"
        ></div>
        <span>Low Density</span>
      </div>
      <div class="legend-item">
        <div
          class="legend-color heat-dot"
          style="background-color: rgba(255, 83, 73, 1.5);"
        ></div>
        <span>High Density</span>
      </div>
    </div>

    <!-- Time Slider Drawer -->
    <el-drawer
      v-model="showTimeSliderDrawer"
      :close-on-click-modal="true"
      direction="btt"
      custom-class="time-slider-drawer"
      :with-header="false"
      :size="'auto'"
      @close="onTimeSliderDrawerClose"
      @opened="onTimeSliderOpened"
    >
      <div class="slider-container">
        <h2>Select Time Range</h2>
        <p>Showing events from the last {{ timeSliderValue }} hour(s)</p>
        <!-- Activity Level Chart -->
        <div id="activity-chart"></div>
        <!-- Time Slider -->
        <el-slider
          v-model="timeSliderValue"
          :min="0.5"
          :max="12"
          :step="0.5"
          show-tooltip
          :marks="timeSliderMarks"
          @change="onTimeSliderChange"
        ></el-slider>
      </div>
    </el-drawer>

    <!-- Settings Panel -->
    <el-drawer
      v-model="showSettings"
      direction="ltr"
      :size="drawerSize"
      :with-header="false"
      custom-class="settings-drawer"
      @opened="onSettingsOpened"
      @closed="onSettingsClosed"
      @open="disableScroll"
      @close="enableScroll"
    >
      <el-card>
        <!-- Map Style Setting -->
        <div class="settings-item">
          <span>Map Style:</span>
          <el-select v-model="selectedMapStyle" placeholder="Select Map Style">
            <el-option label="Default" value="default"></el-option>
            <el-option label="Satellite" value="satellite"></el-option>
            <el-option label="Dark" value="dark"></el-option>
          </el-select>
        </div>
        <!-- Marker Color Scheme Setting -->
        <div class="settings-item">
          <span>Marker Color Scheme:</span>
          <el-select
            v-model="selectedColorScheme"
            placeholder="Select Color Scheme"
          >
            <el-option label="Blue to Red" value="blue-red"></el-option>
            <el-option label="Green-Yellow" value="green-yellow"></el-option>
          </el-select>
        </div>
        <!-- Heatmap Toggle -->
        <div class="settings-item">
          <el-switch v-model="showHeatmap" active-text="Show Heatmap"></el-switch>
        </div>
        <!-- Heatmap Strength Slider -->
        <div class="settings-item" v-if="showHeatmap">
          <span>Heatmap Strength:</span>
          <el-slider
            v-model="heatmapStrength"
            :min="1"
            :max="100"
            :step="1"
            show-input
          ></el-slider>
        </div>
        <!-- Temperature Layer Toggle -->
        <div class="settings-item">
          <el-switch
            v-model="showTemperatureLayer"
            active-text="Show Temperature Layer"
          ></el-switch>
        </div>
        <!-- Markers Toggle -->
        <div class="settings-item">
          <el-switch v-model="showMarkers" active-text="Show Markers"></el-switch>
        </div>
        <!-- Additional Filtering Options -->
        <div class="settings-item">
          <span>Filter by Time:</span>
          <el-select v-model="selectedTimeFilter" placeholder="Select Time Range">
            <el-option label="Last Hour" value="last_hour"></el-option>
            <el-option label="Last 6 Hours" value="last_6_hours"></el-option>
            <el-option label="Last 12 Hours" value="last_12_hours"></el-option>
            <el-option label="All Time" value="all_time"></el-option>
          </el-select>
        </div>
        <div class="settings-item">
          <span>Filter by Location:</span>
          <el-select
            v-model="selectedLocationFilter"
            multiple
            placeholder="Select Locations"
            filterable
            clearable
          >
            <el-option
              v-for="location in uniqueLocations"
              :key="location"
              :label="location"
              :value="location"
            ></el-option>
          </el-select>
        </div>

        <!-- Statistics Section -->
        <div class="statistics-section">
          <h3>Statistics</h3>
          <div class="stat-item">
            <span>Total Events:</span>
            <strong>{{ totalEvents }}</strong>
          </div>
          <div class="stat-item">
            <span>Events in Last Hour:</span>
            <strong>{{ eventsLastHour }}</strong>
          </div>
          <div class="stat-item">
            <span>Most Active Location:</span>
            <strong>{{ mostActiveLocation || 'N/A' }}</strong>
          </div>
        </div>

        <!-- Activity Map -->
        <div id="activity-map" style="width: 100%; height: 400px; margin-top: 20px;"></div>
      </el-card>
    </el-drawer>

    <!-- Bottom Sheet Feed -->
    <transition name="slide-up">
      <div
        v-if="isFeedVisible"
        class="bottom-sheet"
        :style="{ transform: `translateY(${translateY}px)` }"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
      >
        <div class="feed-header">
          <el-tabs v-model="activeLocation">
            <el-tab-pane label="All" name="all"></el-tab-pane>
            <el-tab-pane
              v-for="location in uniqueLocations"
              :key="location"
              :label="location"
              :name="location"
            ></el-tab-pane>
          </el-tabs>
          <el-button @click="toggleFeed">Close</el-button>
        </div>
        <el-scrollbar height="300px" class="feed-container">
          <!-- Filter Section within Feed -->
          <div class="feed-filters">
            <el-input
              v-model="searchQuery"
              placeholder="Search events..."
              clearable
              @clear="applyFilters"
              @input="applyFilters"
            >
              <template #append>
                <el-button icon="el-icon-search"></el-button>
              </template>
            </el-input>
          </div>
          <!-- Event List -->
          <div
            v-for="event in filteredEvents"
            :key="event.id"
            :class="['feed-item', { highlighted: highlightedEvent === event.id }]"
          >
            <el-card shadow="never" class="event-card">
              <div class="event-header">
                <span class="event-time">{{ formatTime(event.timestamp) }}</span>
                <el-button
                  type="text"
                  size="small"
                  @click="centerMapOnEvent(event)"
                >
                  View on Map
                </el-button>
                <el-icon @click="zoomToLocation(event.lat, event.lng)">
                  <Location />
                </el-icon>
              </div>
              <div class="event-description">
                {{ event.summarized_message }}
                <!-- Display times reported if more than once -->
                <div v-if="event.timesReported > 1" class="times-reported">
                  Reported in {{ event.timesReported }} different groups
                </div>
              </div>
              <!-- Display hashtags -->
              <div class="flex gap-2">
                <el-tag
                  v-for="tag in event.hashtags"
                  :key="tag.name"
                  closable
                  type="info"
                >
                  {{ tag.name }}
                </el-tag>
              </div>
            </el-card>
          </div>
        </el-scrollbar>
      </div>
    </transition>

    <!-- Overlay to close feed when clicking outside -->
    <div v-if="isFeedVisible" class="overlay" @click="closeFeed"></div>

    <!-- About Popup Dialog -->
    <el-dialog
      v-model="showAbout"
      title="About"
      :close-on-click-modal="false"
      width="80%"
      @open="disableScroll"
      @close="enableScroll"
    >
      <span>
        This app provides real-time updates on events happening around you,
        displaying them on an interactive map. Events are color-coded based on
        their recency, with red markers indicating the most recent events and blue
        markers representing older ones. If you find this app useful, consider
        supporting me by making a donation. Your support helps me keep the service
        running and improving.
      </span>
      <br /><br />
      <el-button type="primary" @click="openDonationLink">Donate</el-button>
      <el-button @click="showAbout = false">Close</el-button>
    </el-dialog>

    <!-- Cookie Consent Dialog -->
    <el-dialog
      v-model="showCookieConsent"
      title="Cookie Consent"
      :close-on-click-modal="false"
      :show-close="false"
      width="50%"
    >
      <span>
        This app uses cookies to enhance your experience. By continuing to browse,
        you agree to our use of cookies.
      </span>
      <br /><br />
      <el-button type="primary" @click="acceptCookies">Accept</el-button>
    </el-dialog>

    <!-- Tour -->
    <el-tour v-model="showTour" :mask="true" type="primary">
      <el-tour-step
        :target="mapElement?.value"
        title="Interactive Map"
        description="This is the interactive map where events are displayed."
      />
      <el-tour-step
        :target="$refs.newsButton.$el"
        title="Event Feed"
        description="Click here to open the event feed."
      />
      <el-tour-step
        :target="$refs.settingsButton.$el"
        title="Settings"
        description="Use this button to open the settings panel."
      />
      <el-tour-step
        :target="$refs.timeToggle.$el"
        title="Time Slider"
        description="Use this button to adjust the time range of displayed events."
      />
      <el-tour-step
        :target="$refs.timeToggleButton.$el"
        title="Real-Time Toggle"
        description="Toggle real-time updates on or off using this button."
      />
      <el-tour-step
        :target="$refs.legend"
        title="Legend"
        description="This legend explains the map symbols and colors."
      />
    </el-tour>

    <!-- Loading Indicator -->
    <div v-if="loading" class="loading-overlay">
      <div class="loader"></div>
      <div class="loading-text">Loading events...</div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted, nextTick } from 'vue';
import {
  ElNotification,
  ElMessage,
  ElButton,
  ElDrawer,
  ElCard,
  ElSwitch,
  ElSelect,
  ElOption,
  ElAffix,
  ElTooltip,
  ElIcon,
  ElScrollbar,
  ElInput,
  ElDialog,
  ElTabs,
  ElTabPane,
  ElTag,
  ElSlider,
  ElBadge,
  ElTour,
} from 'element-plus';
import 'element-plus/dist/index.css';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet.heat';

import {
  Compass,
  Tickets,
  Location,
  Setting,
  Clock,
  ArrowUp,
  ArrowDown,
} from '@element-plus/icons-vue';

import * as echarts from 'echarts'; // Import ECharts

// Helper function to convert RGB to Hex
const rgbToHex = (r, g, b) => {
  const toHex = (value) => {
    const hex = value.toString(16).padStart(2, '0');
    return hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// Define Custom Pane Name
const paneName = 'customMarkerPane';

// Map and Event Data References
const map = ref(null);
const mapElement = ref(null);
const events = ref([]);
const heatmapLayer = ref(null);
const markerLayer = ref(null);
const markers = ref([]);

// UI State References
const buttonText = ref('Real-Time');
const buttonType = ref('success');
const realTimeStream = ref(true);
const showAbout = ref(false);
const loading = ref(false);
const antiSpam = ref(false);
const newEventCount = ref(0);
const isFeedVisible = ref(false);
const activeLocation = ref('all');
const highlightedEvent = ref(null);
const searchQuery = ref('');
const showSettings = ref(false);
const drawerSize = ref('30%');
let eventSource = null;
let trafficInterval = null;
let cleanupInterval = null; // Interval for cleaning up old events

// Tour State
const showTour = ref(false);

// Refs for tour targets
const newsButton = ref(null);
const settingsButton = ref(null);
const timeToggleButton = ref(null);
const timeToggle = ref(null);
const legend = ref(null);

// Touch Events for Bottom Sheet
const translateY = ref(0);
const startY = ref(0);

// Traffic-Related Variables
const trafficPercentage = ref(0);
const previousHalfHourCount = ref(0);
const currentHalfHourCount = ref(0);

// Most Active Location Variable
const mostActiveLocation = ref(null);

// Settings Variables
const selectedMapStyle = ref('default');
const selectedColorScheme = ref('blue-red'); // Default to blue-red
const showHeatmap = ref(true);
const showMarkers = ref(true);
const showTemperatureLayer = ref(false); // Temperature Layer Toggle
const selectedTimeFilter = ref('all_time');
const selectedLocationFilter = ref([]);
const heatmapStrength = ref(50); // Default heatmap strength

// Time Slider Variables
const showTimeSliderDrawer = ref(false);
const timeSliderValue = ref(12); // Default to last 12 hours
const timeSliderMarks = ref({
  0.5: '0.5h',
  1: '1h',
  2: '2h',
  4: '4h',
  6: '6h',
  8: '8h',
  10: '10h',
  12: '12h',
});

// Initialize Tile Layers
const tileLayers = {
  default: null,
  satellite: null,
  dark: null,
};

// ECharts Overlay Variables
const showEChartsOverlay = ref(false); // Controls the toggle state
const echartsOverlayChart = ref(null); // Holds the ECharts instance

// Cookie Consent
const showCookieConsent = ref(false);

// Compute Traffic Percentage and Direction
const trafficColorClass = computed(() => {
  return trafficPercentage.value > 0 ? 'traffic-rising' : 'traffic-falling';
});

// Compute Unique Locations for Filtering
const uniqueLocations = computed(() => {
  const locations = filteredEvents.value.map((event) => event.location).filter(Boolean);
  return [...new Set(locations)];
});

// Computed for Filtered Events
const filteredEvents = computed(() => {
  let filtered = events.value || [];

  // Apply Time Filter based on timeSliderValue
  const now = Date.now();
  const timeThreshold = now - timeSliderValue.value * 60 * 60 * 1000;
  filtered = filtered.filter((event) => event.timestamp * 1000 >= timeThreshold);

  // Apply Location Tab Filter
  if (activeLocation.value !== 'all') {
    filtered = filtered.filter((event) => event.location === activeLocation.value);
  }

  // Apply Location Settings Filter
  if (selectedLocationFilter.value.length > 0) {
    filtered = filtered.filter((event) =>
      selectedLocationFilter.value.includes(event.location)
    );
  }

  // Apply Search Query
  if (searchQuery.value) {
    const query = searchQuery.value.toLowerCase();
    filtered = filtered.filter((event) =>
      event.summarized_message.toLowerCase().includes(query)
    );
  }

  return filtered;
});

// Compute Minimum and Maximum Timestamps for Color Scaling
const minTimestamp = computed(() => {
  if (events.value.length === 0) return 0;
  return Math.min(...events.value.map((e) => e.timestamp));
});

const maxTimestamp = computed(() => {
  if (events.value.length === 0) return 0;
  return Math.max(...events.value.map((e) => e.timestamp));
});

// Function to Get Color Based on Event Timestamp
const getColorForEvent = (timestamp) => {
  if (minTimestamp.value === maxTimestamp.value) {
    return '#FF0000'; // All events have the same timestamp
  }

  const t =
    (timestamp - minTimestamp.value) / (maxTimestamp.value - minTimestamp.value); // Normalized time between 0 and 1

  let color;

  if (selectedColorScheme.value === 'blue-red') {
    // Interpolate between blue and red
    const r = Math.round(255 * t);
    const g = 0;
    const b = Math.round(255 * (1 - t));
    color = rgbToHex(r, g, b);
  } else if (selectedColorScheme.value === 'green-yellow') {
    // Interpolate between green and yellow
    const r = Math.round(255 * t);
    const g = 255;
    const b = 0;
    color = rgbToHex(r, g, b);
  } else {
    // Default to blue-red if no valid scheme is selected
    const r = Math.round(255 * t);
    const g = 0;
    const b = Math.round(255 * (1 - t));
    color = rgbToHex(r, g, b);
  }

  return color;
};

// Traffic Calculation
const calculateTraffic = () => {
  const now = Date.now();
  const thirtyMinutesAgo = now - 30 * 60 * 1000;

  const eventsLast30Minutes = events.value.filter(
    (event) => event.timestamp * 1000 >= thirtyMinutesAgo
  );
  const eventsPrevious30Minutes = events.value.filter(
    (event) =>
      event.timestamp * 1000 < thirtyMinutesAgo &&
      event.timestamp * 1000 >= thirtyMinutesAgo - 30 * 60 * 1000
  );

  currentHalfHourCount.value = eventsLast30Minutes.length;
  previousHalfHourCount.value = eventsPrevious30Minutes.length;

  if (previousHalfHourCount.value > 0) {
    trafficPercentage.value = Math.round(
      ((currentHalfHourCount.value - previousHalfHourCount.value) /
        previousHalfHourCount.value) *
        100
    );
  } else {
    trafficPercentage.value = currentHalfHourCount.value > 0 ? 100 : 0;
  }
};

// Function to Calculate the Most Active Location
const calculateMostActiveLocation = () => {
  const locationCounts = filteredEvents.value.reduce((acc, event) => {
    if (event.location) {
      acc[event.location] = (acc[event.location] || 0) + 1;
    }
    return acc;
  }, {});

  const sortedLocations = Object.entries(locationCounts).sort(
    (a, b) => b[1] - a[1]
  );

  mostActiveLocation.value = sortedLocations.length ? sortedLocations[0][0] : null;
};

// Function to Center on the Most Active Location
const centerOnMostActive = () => {
  if (!mostActiveLocation.value) {
    ElMessage.info('No active location to center on.');
    return;
  }

  const event = events.value.find((event) => event.location === mostActiveLocation.value);

  if (event) {
    zoomToLocation(event.lat, event.lng, 9);
  } else {
    ElMessage.error('Could not find the most active location on the map.');
  }
};

// Function to Center the Map on Israel with Zoom Level 7
const centerMapOnIsrael = () => {
  if (map.value) {
    map.value.setView([31.5, 35], 6);
  }
};

// Initialize Leaflet Map
const initMap = async () => {
  await nextTick(); // Ensure DOM is updated

  if (!mapElement.value) {
    console.error('Map element not found!');
    return;
  }

  // Initialize the map
  map.value = L.map(mapElement.value).setView([31.5, 35], 7);

  // Initialize all tile layers
  tileLayers.default = L.tileLayer(
    'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    {
      attribution: '© OpenStreetMap contributors',
    }
  );

  tileLayers.satellite = L.tileLayer(
    'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{x}/{y}',
    {
      attribution: 'Tiles © Esri',
    }
  );

  tileLayers.dark = L.tileLayer(
    'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
    {
      attribution: '© OpenStreetMap contributors © CARTO',
      subdomains: 'abcd',
      maxZoom: 19,
    }
  );

  // Add selected tile layer to the map
  tileLayers[selectedMapStyle.value].addTo(map.value);

  // Create Custom Pane
  map.value.createPane(paneName);
  map.value.getPane(paneName).style.zIndex = 650; // Adjust z-index as needed

  // Initialize Heatmap Layer
  heatmapLayer.value = L.heatLayer([], {
    radius: (heatmapStrength.value / 100) * 50 + 10,
    blur: (heatmapStrength.value / 100) * 40 + 10,
    maxZoom: 17,
    minOpacity: 0.5,
    gradient: {
      0.4: 'blue',
      0.6: 'cyan',
      0.7: 'lime',
      0.8: 'yellow',
      1: 'red',
    },
  });

  if (showHeatmap.value || showTemperatureLayer.value) {
    heatmapLayer.value.addTo(map.value);
  }

  // Initialize Marker Layer
  markerLayer.value = L.layerGroup();

  if (showMarkers.value) {
    markerLayer.value.addTo(map.value);
  }

  // Handle Map Resizing Issues
  map.value.invalidateSize();
};

// Watchers for Reactive Changes

// Watcher for heatmapStrength
watch(heatmapStrength, (newVal) => {
  if (heatmapLayer.value) {
    const newRadius = (newVal / 100) * 50 + 10; // Radius between 10 and 60
    const newBlur = (newVal / 100) * 40 + 10; // Blur between 10 and 50
    heatmapLayer.value.setOptions({
      radius: newRadius,
      blur: newBlur,
    });
    updateHeatmap();
    saveSettings();
  }
});

// Watcher for selectedMapStyle
watch(selectedMapStyle, (newVal, oldVal) => {
  if (map.value) {
    map.value.removeLayer(tileLayers[oldVal]);
    tileLayers[newVal].addTo(map.value);
    saveSettings();
  }
});

// Watcher for showMarkers
watch(showMarkers, (newVal) => {
  if (map.value && markerLayer.value) {
    if (newVal) {
      markerLayer.value.addTo(map.value);
    } else {
      map.value.removeLayer(markerLayer.value);
    }
    saveSettings();
  }
});

// Watcher for showHeatmap
watch(showHeatmap, (newVal) => {
  if (map.value && heatmapLayer.value) {
    if (newVal) {
      if (!map.value.hasLayer(heatmapLayer.value)) {
        heatmapLayer.value.addTo(map.value);
      }
      updateHeatmap();
    } else {
      if (!showTemperatureLayer.value) {
        map.value.removeLayer(heatmapLayer.value);
      }
    }
    saveSettings();
  }
});

// Watcher for showTemperatureLayer
watch(showTemperatureLayer, (newVal) => {
  if (map.value && heatmapLayer.value) {
    if (newVal) {
      heatmapLayer.value.setOptions({
        gradient: {
          0.0: 'blue',
          0.2: 'cyan',
          0.4: 'lime',
          0.6: 'yellow',
          0.8: 'orange',
          1.0: 'red',
        },
      });
      if (!map.value.hasLayer(heatmapLayer.value)) {
        heatmapLayer.value.addTo(map.value);
      }
      updateHeatmap();
    } else {
      heatmapLayer.value.setOptions({
        gradient: {
          0.4: 'blue',
          0.6: 'cyan',
          0.7: 'lime',
          0.8: 'yellow',
          1: 'red',
        },
      });
      if (!showHeatmap.value) {
        map.value.removeLayer(heatmapLayer.value);
      }
      updateHeatmap();
    }
    saveSettings();
  }
});

// Watcher for filteredEvents
watch(filteredEvents, () => {
  updateMap();
});

// Watcher for realTimeStream
watch(realTimeStream, (newVal) => {
  if (newVal) {
    initSSE();
    ElMessage.info('Real-Time Updates Enabled');
    buttonType.value = 'success'; // Set button color to green when connected
    buttonText.value = 'Real-Time';
  } else {
    closeSSE();
    buttonText.value = 'Disconnected';
    buttonType.value = 'danger';
    ElMessage.info('Real-Time Updates Disabled');
  }
});

// Watcher for selectedColorScheme
watch(selectedColorScheme, () => {
  updateMap();
  saveSettings();
});

// Watcher for timeSliderValue to apply filters
watch(timeSliderValue, () => {
  applyFilters();
  if (activityChart.value) {
    updateActivityChart();
  }
});

// Watcher for showEChartsOverlay
watch(showEChartsOverlay, (newVal) => {
  if (newVal) {
    initEChartsOverlayChart();
  } else {
    disposeEChartsOverlayChart();
  }
});

// Watcher for Changes in Events to Recalculate Traffic and Most Active Location
watch(
  events,
  () => {
    calculateTraffic();
    calculateMostActiveLocation();
  },
  { immediate: true }
);

// Lifecycle Hooks
onMounted(async () => {
  await initMap();

  loadSettings();

  await loadEvents();
  if (realTimeStream.value) {
    initSSE();
  }

  // Show Tour on First Two Visits
  const visitCount = localStorage.getItem('visitCount') || 0;
  if (visitCount < 2) {
    showTour.value = true;
    localStorage.setItem('visitCount', parseInt(visitCount) + 1);
  }

  // Set Up Interval to Update Traffic Meter Every Minute
  trafficInterval = setInterval(calculateTraffic, 60000); // 60000 ms = 1 minute

  // Set Up Interval to Remove Old Events Every Minute
  cleanupInterval = setInterval(removeOldEvents, 60 * 1000);

  // Adjust Drawer Size for Mobile Devices
  adjustDrawerSize();

  window.addEventListener('resize', adjustDrawerSize);
  window.addEventListener('resize', resizeEChartsOverlayChart);

  // Check for Cookie Consent
  if (!localStorage.getItem('cookiesAccepted')) {
    showCookieConsent.value = true;
  }
});

onUnmounted(() => {
  if (trafficInterval) {
    clearInterval(trafficInterval);
  }
  if (cleanupInterval) {
    clearInterval(cleanupInterval);
  }
  window.removeEventListener('resize', adjustDrawerSize);
  window.removeEventListener('resize', resizeEChartsOverlayChart);
  closeSSE();
  disposeEChartsOverlayChart();

  if (map.value) {
    map.value.remove();
    map.value = null;
  }
});

// Utility Functions

// Function to Remove Events Older than 12 Hours
const removeOldEvents = () => {
  const now = Date.now();
  const twelveHoursAgo = now - 12 * 60 * 60 * 1000;
  events.value = events.value.filter(event => event.timestamp * 1000 >= twelveHoursAgo);
};

// Function to Apply Filters
const applyFilters = () => {
  // Since 'filteredEvents' is a computed property,
  // the map and feed will update automatically.
};

// Function to Update Heatmap
const updateHeatmap = () => {
  if (!heatmapLayer.value || !map.value.hasLayer(heatmapLayer.value)) return;

  const heatData = filteredEvents.value.map((event) => [
    event.lat,
    event.lng,
    1, // You can adjust the intensity based on your data
  ]);
  heatmapLayer.value.setLatLngs(heatData);
};

// Function to Update Map Markers
const updateMap = () => {
  if (!markerLayer.value) return;

  // Clear existing markers
  markerLayer.value.clearLayers();
  markers.value = [];

  filteredEvents.value.forEach((event) => {
    addMarker(event);
  });

  updateHeatmap();
};

// Function to Load Events from Last 12 Hours
const loadEvents = async () => {
  loading.value = true;
  try {
    const twelveHoursAgo = Math.floor((Date.now() - 12 * 60 * 60 * 1000) / 1000); // in seconds
    const response = await fetch(`/api/events?since=${twelveHoursAgo}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();

    // Generate hashtags and assign colors for each event
    data.forEach((event) => {
      event.hashtags = generateHashtags(event.keywords || []);
      event.timesReported = 1; // Initialize timesReported
    });

    // Merge new events with existing events
    events.value = mergeEvents(data, events.value);

    // Remove events older than 12 hours
    removeOldEvents();

    updateMap();
    focusOnNewestEvent();
    applyFilters();
  } catch (error) {
    console.error('Error loading events:', error);
    ElMessage.error('Error loading events');
    events.value = events.value || [];
  } finally {
    loading.value = false;
  }
};

// Function to Initialize Server-Sent Events
const initSSE = () => {
  if (eventSource) return;

  eventSource = new EventSource('/api/events/stream');

  eventSource.onmessage = (event) => {
    const newEvents = JSON.parse(event.data);

    if (Array.isArray(newEvents)) {
      newEvents.forEach((eventData) => processEvent(eventData));
    } else {
      processEvent(newEvents);
    }
  };

  eventSource.onerror = () => {
    // Handle SSE connection error
    realTimeStream.value = false;
    buttonText.value = 'Disconnected';
    buttonType.value = 'danger';
    ElMessage.error('Failed to connect to real-time updates');
    closeSSE();
  };
};

// Function to Close Server-Sent Events
const closeSSE = () => {
  if (eventSource) {
    eventSource.close();
    eventSource = null;
  }
};

// Function to Toggle Real-Time Updates
const toggleRealTime = async () => {
  if (antiSpam.value) return;

  antiSpam.value = true;
  setTimeout(async () => {
    antiSpam.value = false;

    if (realTimeStream.value) {
      realTimeStream.value = false;
      closeSSE();
    } else {
      await fetchRecentData();
      realTimeStream.value = true;
      initSSE();
    }
  }, 2000);
};

// Function to Fetch Recent Data from API
const fetchRecentData = async () => {
  loading.value = true;
  try {
    const twelveHoursAgo = Math.floor((Date.now() - 12 * 60 * 60 * 1000) / 1000); // in seconds
    const response = await fetch(`/api/events?since=${twelveHoursAgo}`);
    if (!response.ok) {
      throw new Error('Failed to fetch recent data');
    }
    const data = await response.json();

    // Generate hashtags and assign colors for each event
    data.forEach((event) => {
      event.hashtags = generateHashtags(event.keywords || []);
      event.timesReported = 1; // Initialize timesReported
    });

    // Merge new events with existing events
    events.value = mergeEvents(data, events.value);

    // Remove events older than 12 hours
    removeOldEvents();

    updateMap();
    applyFilters();
  } catch (error) {
    console.error('Error fetching recent data:', error);
    ElMessage.error('Error fetching recent data');
  } finally {
    loading.value = false;
  }
};

// Function to Process Incoming Event from SSE
const processEvent = (eventData) => {
  const existingEvent = events.value.find(
    (event) => event.summarized_message === eventData.summarized_message
  );

  if (existingEvent) {
    // If the message already exists, increment timesReported
    existingEvent.timesReported += 1;
  } else {
    eventData.hashtags = generateHashtags(eventData.keywords || []);
    eventData.timesReported = 1; // Initialize timesReported
    events.value.unshift(eventData);
    newEventCount.value++;
    addHeatmapData(eventData);
    addMarker(eventData);
    highlightNewEvent(eventData);
    applyFilters();

    // Add a Notification When a New Event is Received
    ElNotification({
      title: 'New Event Received',
      message: eventData.summarized_message,
      type: 'info',
      duration: 4000,
      onClick: () => {
        zoomToLocation(eventData.lat, eventData.lng);
      },
    });
  }

  // Remove events older than 12 hours
  removeOldEvents();
};

// Function to Add Heatmap Data
const addHeatmapData = (eventData) => {
  const { lat, lng } = eventData;
  if (heatmapLayer.value && map.value.hasLayer(heatmapLayer.value)) {
    heatmapLayer.value.addLatLng([lat, lng]);
  }
};

// Function to Add Marker to Map
const addMarker = (eventData) => {
  let { lat, lng, timestamp } = eventData;

  if (lat === undefined || lng === undefined || isNaN(lat) || isNaN(lng)) {
    console.error('Invalid LatLng:', lat, lng);
    return;
  }

  // Introduce jitter to prevent marker overlap
  const jitterAmount = 0.015; // Adjust this value as needed
  lat = lat + (Math.random() - 0.5) * jitterAmount;
  lng = lng + (Math.random() - 0.5) * jitterAmount;

  // Get the color based on the timestamp
  const color = getColorForEvent(timestamp);

  // Create the marker with the jittered coordinates
  const marker = L.circleMarker([lat, lng], {
    pane: paneName, // Assign to custom pane
    radius: 6,
    fillColor: color,
    color: color,
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  }).addTo(markerLayer.value);

  marker.eventData = eventData;
  markers.value.push(marker);

  // Show Popup on Marker Click
  marker.on('click', () => {
    const timesReportedText =
      eventData.timesReported > 1
        ? `<br><strong>Reported in ${eventData.timesReported} different groups</strong>`
        : '';

    const popupContent = `
      <strong>${formatTime(eventData.timestamp)}</strong><br>
      ${eventData.summarized_message}
      ${timesReportedText}
    `;
    L.popup()
      .setLatLng([lat, lng])
      .setContent(popupContent)
      .openOn(map.value);
  });

  // Zoom In on Double-Click
  marker.on('dblclick', () => {
    if (map.value) {
      map.value.setView([lat, lng], map.value.getZoom() + 6);
    }
  });
};

// Function to Generate Hashtags Based on Keywords
const generateHashtags = (keywords) => {
  return keywords.map((keyword) => ({
    name: `#${keyword}`,
    type: 'info',
  }));
};

// Function to Merge New Events with Existing Events
const mergeEvents = (newEvents, existingEvents) => {
  const mergedEvents = [...existingEvents];

  newEvents.forEach((newEvent) => {
    const existingEvent = mergedEvents.find(
      (event) => event.summarized_message === newEvent.summarized_message
    );

    if (existingEvent) {
      existingEvent.timesReported += 1;
    } else {
      newEvent.timesReported = 1;
      mergedEvents.push(newEvent);
    }
  });

  // Remove events older than 12 hours
  const now = Date.now();
  const twelveHoursAgo = now - 12 * 60 * 60 * 1000;
  const filteredEvents = mergedEvents.filter(
    (event) => event.timestamp * 1000 >= twelveHoursAgo
  );

  // Sort events by timestamp (newest first)
  filteredEvents.sort((a, b) => b.timestamp - a.timestamp);

  return filteredEvents;
};

// Function to Focus on the Newest Event
const focusOnNewestEvent = () => {
  if (filteredEvents.value.length > 0) {
    const newestEvent = filteredEvents.value[0];
    zoomToLocation(newestEvent.lat, newestEvent.lng, 7);
  }
};

// Function to Format Time
const formatTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString();
};

// Function to Center Map on an Event
const centerMapOnEvent = (eventData) => {
  zoomToLocation(eventData.lat, eventData.lng, 13);
};

// Function to Zoom to a Specific Location
const zoomToLocation = (lat, lng, zoomLevel = 13) => {
  if (map.value) {
    map.value.setView([lat, lng], zoomLevel);
  } else {
    ElMessage.error('Map is not initialized.');
  }
};

// Function to Highlight New Event in Feed
const highlightNewEvent = (eventData) => {
  highlightedEvent.value = eventData.id;
  setTimeout(() => {
    highlightedEvent.value = null;
  }, 30000);
};

// Function to Adjust Drawer Size Based on Screen Width
const adjustDrawerSize = () => {
  const screenWidth = window.innerWidth;
  if (screenWidth <= 600) {
    drawerSize.value = '80%';
  } else {
    drawerSize.value = '30%';
  }
};

// Function to Toggle Settings Panel
const toggleSettings = () => {
  showSettings.value = !showSettings.value;
};

// Function to Toggle Feed Visibility
const toggleFeed = () => {
  isFeedVisible.value = !isFeedVisible.value;
  if (isFeedVisible.value) {
    newEventCount.value = 0;
    disableScroll();
  } else {
    enableScroll();
  }
};

// Function to Close Feed
const closeFeed = () => {
  isFeedVisible.value = false;
  enableScroll();
};

// Function to Toggle About Dialog
const toggleAbout = () => {
  showAbout.value = !showAbout.value;
  if (showAbout.value) {
    disableScroll();
  } else {
    enableScroll();
  }
};

// Function to Disable Page Scroll
const disableScroll = () => {
  document.body.style.overflow = 'hidden';
};

// Function to Enable Page Scroll
const enableScroll = () => {
  document.body.style.overflow = '';
};

// Function to Open Donation Link
const openDonationLink = () => {
  window.open('https://buymeacoffee.com/david123', '_blank');
};

// Touch Event Handlers for Bottom Sheet
const onTouchStart = (e) => {
  startY.value = e.touches[0].clientY;
};

const onTouchMove = (e) => {
  const currentY = e.touches[0].clientY;
  translateY.value = currentY - startY.value;
};

const onTouchEnd = () => {
  if (translateY.value > 100) {
    isFeedVisible.value = false;
    enableScroll();
  }
  translateY.value = 0;
};

// Function to Save Settings to Local Storage
const saveSettings = () => {
  const settings = {
    selectedMapStyle: selectedMapStyle.value,
    selectedColorScheme: selectedColorScheme.value,
    showHeatmap: showHeatmap.value,
    showMarkers: showMarkers.value,
    showTemperatureLayer: showTemperatureLayer.value,
    heatmapStrength: heatmapStrength.value,
    selectedTimeFilter: selectedTimeFilter.value,
    selectedLocationFilter: selectedLocationFilter.value,
    showEChartsOverlay: showEChartsOverlay.value,
  };
  localStorage.setItem('mapSettings', JSON.stringify(settings));
};

// Function to Load Settings from Local Storage
const loadSettings = () => {
  const settings = JSON.parse(localStorage.getItem('mapSettings'));
  if (settings) {
    selectedMapStyle.value = settings.selectedMapStyle || 'default';
    selectedColorScheme.value = settings.selectedColorScheme || 'blue-red';
    showHeatmap.value = settings.showHeatmap !== undefined ? settings.showHeatmap : true;
    showMarkers.value = settings.showMarkers !== undefined ? settings.showMarkers : true;
    showTemperatureLayer.value =
      settings.showTemperatureLayer !== undefined ? settings.showTemperatureLayer : false;
    heatmapStrength.value = settings.heatmapStrength || 50;
    selectedTimeFilter.value = settings.selectedTimeFilter || 'all_time';
    selectedLocationFilter.value = settings.selectedLocationFilter || [];
    showEChartsOverlay.value = settings.showEChartsOverlay || false;
  }
};

// Function to Accept Cookies
const acceptCookies = () => {
  localStorage.setItem('cookiesAccepted', 'true');
  showCookieConsent.value = false;
};

// Computed properties for statistics
const totalEvents = computed(() => events.value.length);

const eventsLastHour = computed(() => {
  const now = Date.now();
  const oneHourAgo = now - 60 * 60 * 1000;
  return events.value.filter((event) => event.timestamp * 1000 >= oneHourAgo).length;
});

// Method to Toggle Time Slider Drawer
const toggleTimeSliderDrawer = () => {
  showTimeSliderDrawer.value = !showTimeSliderDrawer.value;
};

// Method to Handle Time Slider Change
const onTimeSliderChange = (newVal) => {
  timeSliderValue.value = newVal;
  applyFilters();
  if (activityChart.value) {
    updateActivityChart();
  }
};

// Handle Drawer Open
const onTimeSliderOpened = async () => {
  await nextTick();
  initActivityChart();
};

// Handle Drawer Close
const onTimeSliderDrawerClose = () => {
  if (activityChart.value) {
    activityChart.value.dispose();
    activityChart.value = null;
  }
};

// Activity Chart Variables
const activityChart = ref(null);

// Initialize Activity Chart
const initActivityChart = () => {
  if (!activityChart.value) {
    const chartDom = document.getElementById('activity-chart');
    if (chartDom) {
      activityChart.value = echarts.init(chartDom);
    }
  }
  if (activityChart.value) {
    updateActivityChart();
  }
};

// Update Activity Chart
const updateActivityChart = () => {
  const data = getActivityData();
  const option = {
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: data.labels,
      boundaryGap: false,
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: [
      {
        data: data.counts,
        type: 'line',
        areaStyle: {},
      },
    ],
  };
  activityChart.value.setOption(option);
};

// Get Activity Data for Chart
const getActivityData = () => {
  const now = Date.now();
  const intervals = 24; // For 12 hours, 30 minutes per interval
  const intervalDuration = 30 * 60 * 1000; // 30 minutes in ms
  const labels = [];
  const counts = [];

  for (let i = intervals - 1; i >= 0; i--) {
    const start = now - (i + 1) * intervalDuration;
    const end = start + intervalDuration;
    const eventsInInterval = events.value.filter(
      (event) => event.timestamp * 1000 >= start && event.timestamp * 1000 < end
    );
    const date = new Date(start);
    const label = `${date.getHours()}:${('0' + date.getMinutes()).slice(-2)}`;
    labels.push(label);
    counts.push(eventsInInterval.length);
  }

  return { labels, counts };
};

// ECharts Overlay Functions
const initEChartsOverlayChart = () => {
  if (echartsOverlayChart.value || !map.value) return;

  const chartDom = document.getElementById('echarts-overlay');
  if (chartDom) {
    echartsOverlayChart.value = echarts.init(chartDom);

    // Set up the initial chart options
    setEChartsOverlayOption();

    // Sync ECharts view with Leaflet map
    map.value.on('move', updateEChartsView);
    map.value.on('zoomend', updateEChartsView);

    // Initial sync
    updateEChartsView();
  }
};

const disposeEChartsOverlayChart = () => {
  if (echartsOverlayChart.value) {
    echartsOverlayChart.value.dispose();
    echartsOverlayChart.value = null;

    // Remove event listeners
    map.value.off('move', updateEChartsView);
    map.value.off('zoomend', updateEChartsView);
  }
};

const setEChartsOverlayOption = () => {
  if (!echartsOverlayChart.value || !map.value) return;

  const convertData = (data) => {
    return data.map((item) => {
      const latLng = new L.LatLng(item.value[1], item.value[0]);
      const point = map.value.latLngToLayerPoint(latLng);
      return {
        ...item,
        value: [point.x, point.y],
      };
    });
  };

  const data = filteredEvents.value.map((event) => ({
    name: event.location || 'Unknown',
    value: [event.lng, event.lat],
  }));

  const size = map.value.getSize();

  const option = {
    animation: false,
    tooltip: {
      trigger: 'item',
      formatter: function (params) {
        return `${params.name}<br/>Coordinates: [${params.data.value[0]}, ${params.data.value[1]}]`;
      },
    },
    xAxis: {
      type: 'value',
      show: false,
      min: 0,
      max: size.x,
    },
    yAxis: {
      type: 'value',
      show: false,
      inverse: true,
      min: 0,
      max: size.y,
    },
    series: [
      {
        type: 'scatter',
        data: convertData(data),
        symbolSize: 10,
        itemStyle: {
          color: 'red',
        },
      },
    ],
  };

  echartsOverlayChart.value.setOption(option);
};

const updateEChartsView = () => {
  if (!echartsOverlayChart.value || !map.value) return;
  setEChartsOverlayOption();
};

const resizeEChartsOverlayChart = () => {
  if (echartsOverlayChart.value) {
    echartsOverlayChart.value.resize();
  }
};

watch([events, filteredEvents], () => {
  if (echartsOverlayChart.value) {
    setEChartsOverlayOption();
  }
});

onMounted(() => {
  window.addEventListener('resize', resizeEChartsOverlayChart);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeEChartsOverlayChart);
  disposeEChartsOverlayChart();
});
</script>

<style scoped>
/* Existing CSS Reset as above */

/* App Container */
#app-container {
  display: flex;
  flex-direction: column;
  height: 100vh; 
  width: 100vw; 
  overflow: hidden;
  position: relative; 
}

/* Map Container */
#map-container {
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0; 
}

.el-button.success {
  background-color: green;
  border-color: green;
}

.el-button.danger {
  background-color: red;
  border-color: red;
}

/* Map Element */
#map {
  width: 100%;
  height: 100%;
}

/* Buttons and Icons */
.center-map-button,
.time-slider-toggle-button,
.settings-button,
.real-time-button,
.news-button {
  position: absolute;
  left: 20px;
  z-index: 1000;
  color: white;
  border: none; 
  background: none; 
}

.center-map-button {
  bottom: 310px;
  left: 5px;
  background-color: #409eff; 
}

.time-slider-toggle-button {
  bottom: 350px;
  left: 5px;
  background-color: #67c23a; 
}

.news-button {
  bottom: 500px;
  left: 5px;
  background-color: #e6a23c; 
}

.settings-button {
  bottom: 270px;
  left: 5px;
  background-color: #909399; 
}

.real-time-button {
  bottom: 20px;
  left: 5px;
  background-color: #f56c6c; 
}

/* Hover Effects */
.center-map-button:hover,
.time-slider-toggle-button:hover,
.settings-button:hover,
.real-time-button:hover,
.news-button:hover {
  transform: scale(1.05);
  background-color: #66b1ff;
}

/* Chart Styles */
#activity-chart {
  width: 150%;
  min-height: 100px;
  margin-bottom: 20px;
}

/* Adjust Tooltip Containers */
.el-tooltip {
  display: block;
}

/* Traffic Meter */
.traffic-meter {
  position: absolute;
  bottom: 60px;
  left: 10px;
  z-index: 1100;
}

/* Logo */
#logo {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  font-weight: bold;
  color: black;
  z-index: 1000;
  cursor: pointer;
  background: none; 
  border: none; 
}

/* Legend */
#legend {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  z-index: 1000;
  box-shadow: none; 
  border-radius: 4px;
  font-size: 16px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.legend-color {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  border-radius: 50%;
}

.heat-dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  filter: blur(2px);
}

/* Traffic Meter Styles */
.statistic-footer {
  display: flex;
  flex-direction: column;
}

.most-active-location {
  background-color: green;
  color: white;
  padding: 4px 10px;
  border-radius: 13px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.most-active-location:hover {
  background-color: #409eff;
  transform: scale(1.05);
}

#app {
  font-family: 'Roboto', sans-serif;
}

.traffic-percentage {
  background-color: var(--el-bg-color-overlay, #409eff);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  margin-bottom: 30px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.traffic-percentage.traffic-rising {
  background-color: #67c23a;
  padding: 2px 6px;
  margin-bottom: 5px;
  font-size: 12px;
  border-radius: 8px;
}

.traffic-percentage.traffic-falling {
  background-color: #f56c6c;
  padding: 2px 6px;
  margin-bottom: 5px;
  font-size: 12px;
  border-radius: 8px;
}

.traffic-icon {
  margin-right: 4px;
  margin-bottom: 1px;
  color: white;
  font-size: 14px;
}

/* Feed Container */
.feed-container {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  padding: 10px;
  box-sizing: border-box;
}

.feed-filters {
  margin-bottom: 10px;
}

.feed-item {
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.event-card {
  border-radius: 10px;
  padding: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.event-card:hover {
  background-color: #f0f0f0;
  transform: scale(1.02);
}

.event-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.event-time {
  font-size: 12px;
  color: #888;
}

.event-description {
  font-size: 14px;
  color: #333;
}

.times-reported {
  font-size: 12px;
  color: #f56c6c;
  margin-top: 5px;
}

.highlighted {
  background-color: yellow;
}

/* Bottom Sheet Feed */
.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: white;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 16px 16px 0 0;
  z-index: 2000;
  transition: transform 0.3s ease;
}

.feed-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f5f5f5;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1500;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.loader {
  display: inline-block;
  width: 80px;
  height: 80px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #409eff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-text {
  margin-top: 10px;
  font-size: 18px;
  color: #409eff;
  font-weight: bold;
  text-align: center;
}

.el-button {
  margin: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.el-button.success {
  background-color: green;
  border-color: green;
}

.el-button.info {
  background-color: blue;
  border-color: blue;
}

.el-button.warning {
  background-color: orange;
  border-color: orange;
}

.el-button.danger {
  background-color: red;
  border-color: red;
}

.el-button:hover {
  transform: scale(1.05);
}

/* Status Circle */
.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: red;
  position: relative;
  left: -5px;
  margin-right: 5px;
}

.status-circle.online {
  background: green;
  animation: blink-animation 2s infinite;
}

.status-circle.offline {
  background: red;
}

@keyframes blink-animation {
  70% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

/* Badge */
.badge {
  background-color: red;
  color: white;
  padding: 2px 5px;
  border-radius: 0;
  margin-left: 8px;
}

#activity-map {
  width: 100%;
  height: 400px;
  margin-top: 20px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .center-map-button,
  .time-slider-toggle-button,
  .settings-button,
  .real-time-button,
  .news-button {
    padding: 6px;
    left: 10px;
  }

  .center-map-button {
    left: 5px;
    bottom: 180px;
  }

  .time-slider-toggle-button {
    left: 5px;
    bottom: 220px;
  }

  .news-button {
    bottom: 340px;
  }

  .settings-button {
    left: 5px;
    bottom: 140px;
  }

  .real-time-button {
    padding: 4px 12px;
    left: 5px;
    bottom: 10px;
  }

  #legend {
    font-size: 12px;
    bottom: 20px;
    padding: 5px;
  }

  .feed-container {
    max-height: 300px;
    width: 90%;
    padding: 5px;
    margin: 0 auto;
  }

  .event-card {
    padding: 5px;
    font-size: 14px;
  }

  .event-time,
  .event-description {
    font-size: 12px;
  }

  .el-scrollbar__wrap {
    max-height: 200px;
  }

  .traffic-meter {
    bottom: 55px;
    left: 10px;
    font-size: 12px;
    z-index: 1100;
  }

  .traffic-percentage {
    padding: 2px 6px;
    margin-bottom: 5px;
    font-size: 12px;
    border-radius: 8px;
  }

  .settings-drawer {
    padding: 10px;
  }

  .settings-item {
    margin-bottom: 15px;
  }
}

/* Donation Button Styles */
.donation-button {
  position: absolute;
  bottom: 150px; /* Adjust this value as needed to position above the Legend */
  right: 10px;  /* Aligns the button to the right */
  z-index: 1000; /* Ensures the button stays above other elements */
  border-radius: 4px; /* Optional: Adds slight rounding to the button corners */
  padding: 8px 16px; /* Optional: Adjusts padding for better clickability */
  background-color: #blue; /* Optional: Customize the button color */
  color: white; /* Ensures the text is readable */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Optional: Adds a subtle shadow */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Adds smooth hover effects */
}

.donation-button:hover {
  background-color: #green; /* Slightly lighter shade on hover */
  transform: scale(1.05); /* Slightly enlarges the button on hover */
}

/* Responsive Adjustments */
@media (max-width: 600px) {
  .donation-button {
    bottom: 130px; /* Adjusts positioning for smaller screens */
    right: 10px;
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .donation-button {
    bottom: 130px; /* Further adjustments for very small screens */
    right: 10px;
    padding: 4px 8px;
    font-size: 12px;
  }
}

/* Ensure the Legend is above the Donation Button if overlapping occurs */
#legend {
  z-index: 999; /* Slightly below the donation button's z-index */
}


@media (max-width: 400px) {
  #legend {
    right: 10px;
    bottom: 20px;
    font-size: 10px;
    padding: 5px;
  }

  .legend-color {
    width: 10px;
    height: 10px;
  }
}

.leaflet-container {
  margin: 0;
  padding: 0;
  border: none;
  height: 100%;
  width: 100%;
}

.v-tour-step {
  z-index: 3000;
}

.v-tour-step__close-button {
  background: transparent;
  border: none;
  font-size: 18px;
}

.v-tour-overlay {
  background: rgba(0, 0, 0, 0.5);
  z-index: 2500;
}

.v-tour-step__content {
  font-size: 14px;
}

.v-tour-step__navigation-button {
  background-color: #409eff;
  color: white;
  border: none;
  padding: 6px 12px;
  margin: 5px;
  cursor: pointer;
}

.v-tour-step__navigation-button:hover {
  background-color: #66b1ff;
}

.el-dialog__wrapper {
  z-index: 2100;
}

.el-dialog {
  max-width: 400px;
}

.el-dialog__body {
  font-size: 14px;
  text-align: center;
}

.el-dialog__footer {
  text-align: center;
}

.el-dialog__footer .el-button {
  margin: 0 10px;
}

.time-slider-drawer {
  height: auto;
  padding: 20px;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin: 0 auto;
}

.slider-container span {
  margin-bottom: 10px;
  font-weight: bold;
}

.el-badge .el-button {
  margin: 0;
}

.el-badge .item {
  display: inline-block;
}

body {
  margin: 0px;
}
</style>
